exports.components = {
  "component---src-pages-1570683264-js": () => import("./../../../src/pages/1570683264.js" /* webpackChunkName: "component---src-pages-1570683264-js" */),
  "component---src-pages-1572456993-js": () => import("./../../../src/pages/1572456993.js" /* webpackChunkName: "component---src-pages-1572456993-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-delta-js": () => import("./../../../src/pages/delta.js" /* webpackChunkName: "component---src-pages-delta-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-password-js": () => import("./../../../src/pages/password.js" /* webpackChunkName: "component---src-pages-password-js" */),
  "component---src-pages-twitch-js": () => import("./../../../src/pages/twitch.js" /* webpackChunkName: "component---src-pages-twitch-js" */)
}

